










































































import { Component, Vue } from 'vue-property-decorator'
import { ITemplate } from '@/api/landingpage/types/templates.types'
import { IProject } from '@/api/landingpage/types/projects.types'
import { previewLive } from '@/api/landingpage/pages'
import { getTemplates } from '@/api/landingpage/templates'
import LandingPreview from '@/modules/landingpages/components/LandingPreview/Index.vue'
import VsImage from '@/modules/landingpages/components/VsImage/Index.vue'

@Component({
    name: 'SelectProjectTemplate',
    components: {
        LandingPreview,
        VsImage,
    },
})
export default class extends Vue {
    private templates: any[] = []

    private defaultProject = {
        html: '',
        css: '',
        components: [],
        style: [],
    }

    private showPreview = false
    private previewContent = ''

    private async previewLive (template: ITemplate): Promise<void> {
        try {
            const data = {
                html: template?.project?.html || '',
                css: template?.project?.css || '',
            }

            this.showPreview = true
            const res = await previewLive(data)
            this.previewContent = res.data
        } catch (e) {
            this.showPreview = false
            this.previewContent = ''
            console.log(e)
        }
    }

    private closePreviewDialog (): void {
        this.previewContent = ''
        this.showPreview = false
    }

    async beforeMount (): Promise<void> {
        try {
            const res = await getTemplates({ populate: 'project', status: 'enabled' })
            this.templates = res.data.docs
        } catch (e) {
            console.log(e)
        }
    }

    private selectTemplate (project: IProject): void {
        this.$emit('project-selected', project)
    }
}
