


































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { getSubdomains, createSubdomain, deleteSubdomain, updateSubdomain } from '@/api/landingpage/subdomains'
import { LandingpageOptionsModule } from '@/store/modules/landingpageoptions'
import { SubdomainDto } from '@/api/landingpage/dto/subdomains.dto'
import VsContainer from '@/components/VsContainer/Index.vue'
import { getPages } from '@/api/landingpage/pages'

@Component({
    name: 'Subdomains',
    components: {
        VsContainer,
    },
})
export default class extends Vue {
    private subdomains: any = []
    private editSubdomainModal = false
    private deleteSubdomainModal = false
    private idSubdomain = ''
    private pages: any[] = []
    private subdomain: SubdomainDto = {
        name: '',
    }

    loading = true
    $refs!: {
        subdomainForm: any
    }

    get renderHostname () {
        const url = new URL(LandingpageOptionsModule.renderUrl)
        return url.hostname
    }

    get numberSubdomains () {
        return this.subdomains.length
    }

    mounted () {
        this.getSubdomains()
    }

    private async getSubdomains () {
        this.loading = true
        try {
            const res = await getSubdomains()
            this.subdomains = res.data
        } catch (e) {
            console.log(e)
            this.subdomains = []
        }
        this.loading = false
    }

    private async openEditSubdomainModal (name?: string, id?: string) {
        this.clearData()
        if (name && id) {
            this.subdomain.name = name
            this.idSubdomain = id
        }
        this.editSubdomainModal = true
    }

    private clearData () {
        this.subdomain.name = ''
        this.idSubdomain = ''
    }

    private saveSubdomain () {
        this.loading = true
        if (this.idSubdomain) return this.editSubdomain()
        return this.addSubdomain()
    }

    private async editSubdomain () {
        try {
            await updateSubdomain(this.subdomain, this.idSubdomain)
            await this.getSubdomains()
            this.editSubdomainModal = false
            this.$root.$vsToast({
                heading: this.$t('landingpages.subdomains.editSuccess'),
                timeout: 3000,
            })
        } catch (e) {
            console.log(e)
            this.$refs.subdomainForm.setErrors({
                url: [this.$t('landingpages.subdomains.urlNotAllowed')],
            })
        }
        this.loading = false
    }

    private async addSubdomain () {
        try {
            await createSubdomain(this.subdomain)
            await this.getSubdomains()
            this.editSubdomainModal = false
            this.$root.$vsToast({
                heading: this.$t('landingpages.subdomains.urlAdded'),
                timeout: 3000,
            })
        } catch (e) {
            console.log(e)
            this.$refs.subdomainForm.setErrors({
                url: [this.$t('landingpages.subdomains.urlNotAllowed')],
            })
        }
        this.loading = false
    }

    private async openDeleteSubdomain (name: string, id: string) {
        this.clearData()
        this.subdomain.name = name
        this.idSubdomain = id
        this.deleteSubdomainModal = true
    }

    private async deleteSubdomain () {
        try {
            await deleteSubdomain(this.idSubdomain)
            await this.getSubdomains()
            this.deleteSubdomainModal = false
            this.$root.$vsToast({
                heading: this.$t('landingpages.subdomains.urlDeleted'),
                timeout: 3000,
            })
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: this.$t('landingpages.subdomains.urlgeneralError'),
                timeout: 3000,
            })
        }
        this.loading = false
    }

    private async getPagesBySubdomain (subdomain: string) {
        this.pages = []
        try {
            const resp = await getPages({ subdomain })
            this.pages = resp.data.docs
        } catch (e) {
            console.log(e)
        }
    }
}
