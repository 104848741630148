










































import { Component, Vue } from 'vue-property-decorator'
import { PageStatus } from '@/api/landingpage/types/pages.types'
import { IProject } from '@/api/landingpage/types/projects.types'
import { CreatePageDto } from '@/api/landingpage/dto/pages.dto'
import { CreateProjectDto } from '@/api/landingpage/dto/projects.dto'
import SelectProjectTemplate from '@/modules/landingpages/components/SelectProjectTemplate/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import GrapejsEditor from './../../components/GrapejsEditor/index.vue'
import {
    createPage,
    checkCreatePage,
} from '@/api/landingpage/pages'
import {
    createProject,
    deleteProject,
} from '@/api/landingpage/projects'
import { get } from 'lodash'
import { AppModule } from '@/store/modules/app'
import { VsToastOptionsObjectInterface } from '@advision/vision/src/components/VsToast/types'

@Component({
    name: 'CreatePage',
    components: {
        SelectProjectTemplate,
        GrapejsEditor,
        VsContainer,
    },
})
export default class extends Vue {
    private loading = false
    private dialog = false
    private selectTemplate = false
    private step = 1

    get is4Dem () {
        return AppModule.consoleConf?.is4Dem
    }

    async beforeMount () {
        this.checkCreatePageAndRedirect()
    }

    private async checkCreatePageAndRedirect () {
        try {
            await checkCreatePage()
        } catch (e) {
            console.log(e)
            this.$router.push({ name: 'PagesCards' })
            this.$root.$vsToast({
                heading: this.$t(`landingpages.CreatePage.errors.PagesLimitReached`).toString(),
                primaryAction: this.is4Dem ? this.$t(`landingpages.CreatePage.errors.linkAction`).toString() : '',
                primaryActionCallback: this.redirectToShopPrices,
                timeout: 5000,
            })
        }
    }

    private redirectToShopPrices () {
        if (this.is4Dem) {
            this.$router.push({
                name: 'prices',
            })
        }
    }

    private editedItem: CreatePageDto = {
        name: '',
        description: '',
        status: PageStatus.disabled,
        projectId: '',
        slug: '',
        favicon: null,
    }

    private defaultItem: CreatePageDto = {
        name: '',
        description: '',
        status: PageStatus.disabled,
        projectId: '',
        slug: '',
        favicon: null,
    }

    private selectedProject: CreateProjectDto | null = null

    private projectSelected (e: IProject) {
        this.selectedProject = e
    }

    private returnBack () {
        this.selectedProject = null
        this.editedItem.name = ''
        this.editedItem.status = PageStatus.disabled
    }

    private changePageName (e: any) {
        this.editedItem.name = e
    }

    private changePage (e: any) {
        this.editedItem = Object.assign(this.editedItem, e)
    }

    private async createPage (e: any, publish?: boolean) {
        if (!this.selectedProject) return
        let createdProject = null
        try {
            const createProjectResp = await createProject(e.project)
            createdProject = createProjectResp.data
            this.editedItem.projectId = createdProject._id
            this.editedItem.status = publish ? PageStatus.enabled : PageStatus.disabled
            const createPageResp = await createPage(this.editedItem)
            const page = createPageResp.data

            if (publish) {
                this.$root.$vsToast({
                    heading: this.$t('landingpages.EditPage.publishSuccess').toString(),
                    primaryAction: this.is4Dem ? this.$t('landingpages.EditPage.showPublish').toString() : '',
                    primaryActionCallback: this.redirectToShopPrices,
                    timeout: 5000,
                })
            } else {
                this.$root.$vsToast({
                    heading: this.$t('landingpages.projects.saveSuccess').toString(),
                    timeout: 5000,
                })
            }

            const redirectObject: any = {
                name: 'EditPage',
                params: {
                    pageId: page._id,
                },
            }
            if (publish) {
                redirectObject.query = {
                    pagePublic: 'true',
                }
            }
            this.$router.push(redirectObject)
        } catch (e) {
            const responseMessage = get(e, 'response.data.message', 'default')
            const toastOption: VsToastOptionsObjectInterface = {
                heading: this.$t(`landingpages.CreatePage.errors.${responseMessage}`).toString(),
                timeout: 5000,
            }
            if (this.is4Dem && (responseMessage === 'PagesLimitReached' || responseMessage === 'PagesPublicReached')) {
                toastOption.primaryAction = this.$t(`landingpages.CreatePage.errors.linkAction`).toString()
                toastOption.primaryActionCallback = this.redirectToShopPrices
            }
            this.$root.$vsToast(toastOption)
            if (createdProject) {
                await deleteProject(createdProject._id)
            }
        }
        e.stopLoading()
    }
}
